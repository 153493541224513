


































































































































































































































































































































































































import FastGameMenu from '@/components/menus/FastGameMenu.vue'
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Common from "@/mixins/Common.vue"
import axios from 'axios'

const SettingsGetter = namespace('settings').Getter
const UIMutation = namespace('ui').Mutation
const AccountGetter = namespace('account').Getter

const AppProps = Vue.extend({
  data: () => {
    return {
      scale_initialized: false,
      valid_credit_sizes: [],
      credit_btc_value: 0,
      isAccountLoaded: false
    }
  },
  mixins: [Common],
  methods: {
    getRandomReelPosition() {
      return Math.floor(Math.random() * 10)
    },
    async getRuleSet() {
      try {
        let res = await axios.get('/api/dice/ruleset')
        if(res.data.result) {
          this.reels = res.data.result.reels
          this.valid_credit_sizes = res.data.result.valid_credit_sizes
          this.getPlatformCurrency == "BTC" ? this.credit_btc_value = 1000 : this.credit_btc_value = 50000
          return res.data.result
        } else {
          throw new Error('invalid response')
        }
      }
      catch(e) {
        this.$router.push('maintenance')
        console.log("something went wrong when trying to get the ruleset")
        return e
      }
    },
    async reseed() {
      try {
        let res = await axios.post('/api/dice/seed')
        if(res.data.server_seed_hash) {
          return res.data.server_seed_hash
        } else {
          throw new Error('invalid response')
        }
      }
      catch(e) {
        console.log("something went wrong when trying to reseed")
        return e
      }
    },
    async initGame() {
      this.isAccountLoaded = true;
      //@ts-ignore
      await this.$loadScript('/js/legacy/dice.js')
      try {
        let ruleset = await this.getRuleSet()
        this.reels = ruleset.reels
        let starting_server_seed_hash = await this.reseed()
        let free_spin_info = { 'left': 0 }
        let creditModal = "#btc_item_" + this.classSuffixFromToken(this.credit_btc_value)
        $(creditModal).addClass("selected");
        // @ts-ignore
        // eslint-disable-next-line no-undef
        init_dice("123", "123", "123", starting_server_seed_hash, [], [], [], ruleset, this.credit_btc_value, 10, free_spin_info);
        this.initializeGameScale()
      } catch (e) {
        console.log("error", e)
      }
    }
  },
  metaInfo: {
    meta: [{
      vmid: 'description',
      name: 'description',
      content: `Play Exclusive Lucky 7's Progressive Dice Online with Bitcoin, Win Big Jackpots & Cashout Instantly at the best Bitcoin Games Casino`
    },
    {
      vmid: 'title',
      name: 'title',
      content: `Dice`
    },
    {
      vmid: 'keywords',
      name: 'keywords',
      content: `bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`
    }],
  },
  watch: {

  },
  created () {
    //@ts-ignore
    this.setFooterDisabled(true)
    this.setBalanceDisabled(false)
  },
  async mounted () {
    // note that its possible the usertoken is not yet available,
    // in this case the watcher will init dice game when the token
    // is available
    this.initGame();
  }
})
@Component({
  components: {
    FastGameMenu
  }
})
export default class Dice extends AppProps {
  //@ts-ignore
  @UIMutation('setFooterDisabled') setFooterDisabled
  @UIMutation('setBalanceDisabled') setBalanceDisabled
  @SettingsGetter getPlatformCurrency
  @AccountGetter userToken
}
